import { render, staticRenderFns } from "./Comisiones.vue?vue&type=template&id=329b885b&scoped=true&"
import script from "./Comisiones.vue?vue&type=script&lang=js&"
export * from "./Comisiones.vue?vue&type=script&lang=js&"
import style0 from "./Comisiones.vue?vue&type=style&index=0&id=329b885b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329b885b",
  null
  
)

export default component.exports