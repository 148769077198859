<template>
  <section class="container">
    <div class="row justify-content-center justify-content-md-between mb-3 ml-2 ml-sm-0">
      <mdb-btn
        flat
        dark-waves
        icon="arrow-left"
        type="button"
        title="Regresar"
        @click="$router.go(-1)"
      >
        Regresar
      </mdb-btn>
    </div>
    <div class="encabezado-detalles">
      <header class="h4-responsive text-center pt-3">Reporte</header>
      <div class="row mx-0 mt-2">
        <div class="col-12 col-md-4">
          <span class="small-block font-titulo">Nombre del reporte</span>
          <p>
            Comisiones
          </p>
        </div>
        <div class="col-12 col-sm">
          <span class="small-block font-titulo">Mes</span>
          <p>
            {{ fechaResumen }}
          </p>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-12 col-sm-6 col-lg-3">
        <mdb-input
          v-model="fechaResumen"
          type="month"
          class="my-2"
          label="Mes"
          outline
        />
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <select
          v-model="corte"
          id="corte-select-form"
          class="custom-select"
        >
          <option class="d-none" value="" disabled>
            Seleccione el corte
          </option>
          <option value="1">Primer corte</option>
          <option value="2">Segundo corte</option>
        </select>
      </div>
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <!-- Mensaje cuando no hay comisiones -->
    <div
      v-else-if="!comisiones.length"
      class="mensaje-no-items py-3"
    >
      <font-awesome-icon
        icon="clipboard-list"
        size="5x"
        class="icono"
      />
      <p class="texto">No hay comisiones disponibles</p>
    </div>
    <ul
      v-else
      class="table despachos"
    >
      <li class="encabezado sticky">
        <div class="row justify-content-between mx-0">
          <div class="col-12 col-sm px-0">
            <header class="h4-responsive">Comisiones</header>
            <p class="descripcion">Reporte de las comisiones por cliente del vendedor.</p>
          </div>
        </div>
        <div class="thead claro">
          <div class="col-12 col-sm">
            Empresa - Sucursal
            <span class="small-block">
              Tipo cliente
            </span>
          </div>
          <div class="col-12 col-sm col-md-2 col-lg-3 text-sm-right">
            Comisión
          </div>
          <div class="col-12 col-sm col-md-2 col-lg-3 text-sm-right">
            Monto
          </div>
        </div>
      </li>
      <li
        class="contenido"
        v-for="c in comisiones"
        :key="c.id_suc"
      >
        <div
          class="col-12 col-sm"
          data-columna="Empresa - Sucursal / Tipo cliente"
        >
          <p>
            {{c.nom_emp}} - {{ c.nom_suc }}
            <span class="small-block">
              {{c.nom_tcl}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Comisión"
        >
          <p>
            {{c.tipo_comision}}%
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Monto"
        >
          <p>
            {{((Number(c.tipo_comision) / 100) * Number(c.mon_fac)).toFixed(2)}}
          </p>
        </div>
      </li>
      <li class="contenido claro">
        <div
          class="col-12 col-sm text-sm-right"
          data-columna="Totales"
        />
        <div
          class="col-12 col-sm col-md-2 col-lg-2 celda-numerica"
          data-columna="Monto"
        >
          <p>
            {{ calcularTotal() }}
          </p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import {mdbInput, mdbBtn} from 'mdbvue';
import {apiPost} from '@/funciones/api.js';
import LoaderKel from '@/components/LoaderKel.vue';

export default {
  name: 'Comisiones',

  components: {
    mdbInput,
    mdbBtn,
    LoaderKel,
  },
  data() {
    return {
      fechaResumen: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      corte: '1',
      comisiones: [],
      cargando: false,
      timeout: null,
    };
  },
  watch: {
    fechaResumen() {
      this.actualizar();
    },
    corte() {
      this.actualizar();
    },
  },
  methods: {
    actualizar() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.cargando = true;
        let data = `y=${this.fechaResumen.split('-')[0]}&m=${this.fechaResumen.split('-')[1]}`;
        data += '&corte='+ this.corte;
        apiPost({s: 'comisionesMv'}, data)
            .then((res) => {
              this.cargando = false;
              this.comisiones = res.data.com;
            });
      }, 500); // delay
    },
    calcularTotal() {
      let total = 0;
      this.comisiones.forEach((c) => {
        total += (Number(c.tipo_comision) / 100) * Number(c.mon_fac);
      });

      return (total).toFixed(2);
    },
  },
};

</script>

<style lang="scss" scoped>
.table.despachos {
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}

</style>
