<template>
  <div class="container">
    <!-- Encabezado de la vista -->
    <div class="vista-encabezado">
      <div class="col h3-responsive titulo">
        Planificador
      </div>
      <div class="col-auto boton-primario">
        <mdb-btn
          color="primario"
          icon="calendar-plus"
          @click="mostrarAgregarAlPlanificador = !mostrarAgregarAlPlanificador"
        >
          Agregar
        </mdb-btn>
      </div>
    </div>
    <!-- Selector de fechas del planificador -->
    <div class="d-flex flex-wrap align-items-center">
      <p class="col user-select-none cursor-pointer text-center text-md-left">
        <small class="small-block">Hoy es</small>
        <span class="h4-responsive">
          {{ fechaActual }}
        </span>
      </p>
      <div class="col-12 col-md-6 col-lg-auto px-0 d-flex flex-wrap align-items-center">
        <mdb-input
          v-model="fechaDesde"
          type="date"
          label="Desde"
          class="col-6 col-lg mx-0 my-0 pl-0 pr-1"
          outline
          @change="consultarVisitas"
        />
        <mdb-input
          v-model="fechaHasta"
          type="date"
          label="Hasta"
          class="col-6 col-lg mx-0 my-0 pr-0 pl-1"
          outline
        />
        <div class="col-12 col-sm-auto px-0">
          <div class="btn-checkgroup-contenedor text-center">
            <input
              v-model="plaVencidas"
              id="plaVencidas"
              type="checkbox"
              name="checkbox-categoria"
              @click="plaVencidas = !plaVencidas"
              >
            <label for="plaVencidas" class="btn-checkgroup my-1 icon-calendar-xmark">
              Planificaciones vencidas
            </label>
          </div>
        </div>
        <mdb-btn
          flat
          dark-waves
          icon="broom"
          class="col col-lg-auto px-2 py-1"
          @click="fechaEn7Dias(fechaActual); fechaDesde = fechaActual"
        >
          Restablecer
        </mdb-btn>
      </div>
    </div>
    <!-- Acordeón -->
    <div v-if="Object.values(zonasp).length">
      <div
        v-for="(fec, i) in zonasp"
        :key="`fec-${i}`"
        class="acordeon secundario"
      >
        <p class="text-capitalize">{{ fechaFormateada(fec.fec_pla) }}</p>
        <div
          v-for="(zona, z) in fec.zonas"
          :key="`zonp-${z}`"
          :class="['item', {'activo': zona.expandida}]"
        >
          <!-- Nombre de la zona -->
          <div
            class="encabezado"
            @click="zona.expandida = !zona.expandida"
          >
            <header>{{ zona.nom_zon }}</header>
            <font-awesome-icon
              icon="chevron-down"
              :class="['icono', {'fa-flip-vertical': zona.expandida}]"
            />
          </div>
          <div
            v-if="zona.expandida"
            class="contenido multiple"
          >
            <!-- Clientes de la zona -->
            <div
              v-for="(sucursal, s) in zona.sucursales"
              :key="`suc-${sucursal.id_emp}-${sucursal.id_suc}-${sucursal.id_pla}`"
              class="item-nvl-2"
            >
              <div
                class="encabezado flex-wrap"
                @click="sucursal.editar || (sucursal.expandida = !sucursal.expandida);
                idSucursalSeleccionada= sucursal.id_suc;
                sucursalSeleccionada = JSON.parse(JSON.stringify(sucursal));
                empresa = {nom_emp : sucursal.nom_emp, id_emp: sucursal.id_emp}"
              >
                <font-awesome-icon
                  :icon="sucursal.expandida ? 'minus' : 'plus'"
                  :class="['icono', {'fa-rotate-90': !sucursal.expandida}]"
                />
                <header class="col px-0 py-2 text-capitalize">
                  {{ sucursal.nom_emp }}, {{ sucursal.nom_suc }}
                </header>
                <div
                  class="col-12 col-md-auto d-flex px-0"
                  @click.stop
                >
                  <mdbBtn
                    flat
                    dark-waves
                    :icon="sucursal.editar ? 'check' :'pen'"
                    :title="sucursal.editar ? 'Guardar' :'Editar planificación'"
                    class="mx-0 my-sm-0 px-2 py-2 col col-md-auto"
                    :disabled="botonDeshabilitado"
                    v-if="sucursal.atrasadas > 0"
                    @click="
                      idPlanificacionSeleccionada = sucursal.id_pla;
                      sucursal.expandida = true;
                      guardarCambio(sucursal.fec_pla, sucursal.hor_pla, sucursal.obs_pla, s, i, z, sucursal.editar)"
                  >
                    <span class="d-none d-sm-inline">
                      {{ sucursal.editar ? 'Guardar' :'Editar' }}
                    </span>
                  </mdbBtn>
                  <mdb-btn
                    flat
                    dark-waves
                    icon="times"
                    v-if="sucursal.editar"
                    :disabled="botonDeshabilitado"
                    @click="sucursal.editar = !sucursal.editar;
                      sucursal.expandida = true; cambioFec = ''; cambioHor = ''"
                  >
                    Cancelar
                  </mdb-btn>
                  <mdbBtn
                    flat
                    dark-waves
                    icon="trash"
                    title="Eliminar visita del planificador"
                    class="mx-0 my-sm-0 px-2 py-2 col col-md-auto"
                    v-if="!sucursal.editar && sucursal.realizadas < 1"
                    :disabled="botonDeshabilitado"
                    @click="idPlanificacionSeleccionada = sucursal.id_pla; modalConfirmacionEliminar = !modalConfirmacionEliminar"
                  >
                    <span class="d-none d-sm-inline">
                      Eliminar
                    </span>
                  </mdbBtn>
                  <mdb-btn
                    flat
                    dark-waves
                    icon="calendar-day"
                    class="mx-0 my-sm-0 px-2 px-sm-3 py-2 col-auto"
                    v-if="!sucursal.editar && sucursal.fec_pla >= fechaDia"
                    @click="
                      idSucursalSeleccionada = sucursal.id_suc;
                      empresa = {nom_emp : sucursal.nom_emp, id_emp: sucursal.id_emp};
                      sucursalSeleccionada = JSON.parse(JSON.stringify(sucursal));
                      mostrarAsideActividad = true;
                      idPlanificacionSeleccionada = sucursal.id_pla;
                    "
                  >
                    Tomar Actividad
                  </mdb-btn>
                </div>
              </div>
              <!-- Datos de la empresa seleccionada -->
              <template v-if="sucursal.expandida">
                <Transition
                  name="custom-classes-transition"
                  enter-active-class="animated fadeIn"
                  leave-active-class="animated fadeOut"
                  mode="out-in"
                >
                  <div key="sucursal" v-if="!sucursal.editar" class="contenido align-items-start py-3 border-top">
                    <div class="col-12 text-capitalize">
                      <small class="d-block text-muted user-select-none">
                        MOTIVOS
                      </small>
                      <div class="row pl-2 mb-1">
                        <mdb-badge
                          v-for="(motivo, m) in sucursal.motivos.split(',')"
                          :key="m"
                          pill
                          color="bg-secundario"
                          class="z-depth-0 mx-1"
                        >
                          {{motivo}}
                        </mdb-badge>
                      </div>
                    </div>
                    <div class="col-12 col-md-5 mb-2 text-capitalize">
                      <span v-if="sucursalesConActividadesPendientes.includes(idSucursalSeleccionada)">Esta sucursal tiene una actividad pendiente por enviar</span>
                      <div class="row">
                        <div class="col-12 col-sm pr-md-0">
                          <small class="d-block text-muted user-select-none">
                            ENCARGADO SUCURSAL
                          </small>
                          {{ sucursal.nom_per }} {{sucursal.ape_per}}
                          <a
                            v-if="sucursal.ema_per_enc && sucursal.ema_per_enc.length"
                            :href="`mailto:${sucursal.ema_per_enc}`"
                            :title="`Email: ${sucursal.ema_per_enc}`"
                            class="d-block text-break text-wrap enlace-inline"
                          >
                            <small>{{sucursal.ema_per_enc}}</small>
                          </a>
                        </div>
                        <a
                          v-if="sucursal.tel_per_enc && sucursal.tel_per_enc.length"
                          :href="`tel:${sucursal.tel_per_enc}`"
                          class="col-12 col-sm-auto btn btn-flat ripple-parent mx-0 my-2 my-sm-0 py-1 px-3 px-md-2"
                          :title="`Teléfono: ${sucursal.tel_per_enc}}`"
                        >
                          <font-awesome-icon
                            icon="phone-alt"
                            class="d-sm-block mr-2 mx-sm-auto fa-lg"
                          />
                          Llamar
                        </a>
                      </div>
                    </div>
                    <div class="col-12 col-md-7 mb-2 text-capitalize">
                      <div class="row">
                        <div class="col-12 col-sm pr-md-0">
                          <small class="d-block text-muted user-select-none">DIRECCIÓN</small>
                          {{ sucursal.dir_suc }}
                        </div>
                        <a
                          v-if="sucursal.tel_suc && sucursal.tel_suc.length"
                          :href="`tel:+${sucursal.tel_suc}`"
                          class="col-12 col-sm-auto btn btn-flat ripple-parent mx-0 my-2 my-sm-0 py-1 px-3 px-md-2"
                          :title="`Teléfono: +${sucursal.tel_suc}`"
                        >
                          <font-awesome-icon
                            icon="phone-alt"
                            class="d-sm-block mr-2 mx-sm-auto fa-lg"
                          />
                          Llamar
                        </a>
                      </div>
                    </div>
                    <p class="col-12 col-sm-6 col-md-5 mb-2">
                      <small class="d-block text-muted user-select-none">
                        ÚLTIMO PEDIDO
                      </small>
                      <router-link
                        v-if="sucursal.fec_cre_ped && sucursal.fec_cre_ped.length"
                        :to="{ name: 'Pedidos', params: { sucursal: sucursal.id_suc } }"
                        class="enlace-inline"
                      >
                        {{ sucursal.fec_cre_ped }}
                      </router-link>
                      <span
                        v-else
                        class="text-muted font-italic user-select-none"
                      >
                        Sin pedido registrado
                      </span>
                    </p>
                    <p class="col-12 col-sm-6 col-md mb-2">
                      <small class="d-block text-muted user-select-none">
                        NOTA DE PLANIFICACIÓN
                      </small>
                      <span :class="{'text-muted font-italic user-select-none': !sucursal.obs_pla}">
                        {{ sucursal.obs_pla ? sucursal.obs_pla : 'Sin especificar' }}
                      </span>
                    </p>
                    <p class="col-12 col-sm-6 col-md-5 mb-2">
                      <small class="d-block text-muted user-select-none">
                        ESTADO SOLVENCIA
                      </small>
                      <a
                        v-if="sucursal.est_fac"
                        :class="asignarColorEstado(sucursal.est_fac)"
                      >
                        {{ sucursal.est_fac }}
                      </a>
                      <span
                        v-else
                        class="text-muted font-italic user-select-none"
                      >
                        Sin estado de solvencia registrado
                      </span>
                    </p>
                    <!-- Se activará después con la integración del sistema administrativo
                    <p class="col-12 col-sm-6 col-md mb-2 text-capitalize">
                      <small class="d-block text-muted user-select-none">
                        SOLVENCIA
                      </small>
                      <mdb-badge
                        pill
                        color="bg-exitoso"
                      >
                        Solvente
                      </mdb-badge>
                    </p> -->
                    <router-link
                      v-if="sucursal.id_emp"
                      :to="{ name: 'Cliente', params: { id: sucursal.id_emp } }"
                      class="btn btn-flat btn-block ripple-parent"
                    >
                      Ver detalles del cliente
                      <font-awesome-icon icon="angle-right" class="ml-2" />
                    </router-link>
                  </div>
                  <div key="editSucursal" v-else>
                    <form
                      class="row col-12 d-sm-flex justify-content-center mt-n2 p-0"
                      @submit.prevent
                    >
                      <div class="col-12 col-sm-6 px-0 py-1 pr-sm-1">
                        <mdb-input
                          v-model="cambioFec"
                          type="date"
                          label="Fecha prevista"
                          class="my-2"
                          invalidFeedback="Escribe una fecha a planificar"
                          outline
                        />
                      </div>
                      <div class="col-12 col-sm-6 px-0 py-1 pl-sm-1">
                        <mdb-input
                          v-model="cambioHor"
                          type="time"
                          label="Hora prevista"
                          class="my-2"
                          invalidFeedback="Escribe una hora a planificar"
                          outline
                        />
                      </div>
                      <div class="col-12">
                        <mdbInput
                          v-model.trim="observacion"
                          type="textarea"
                          label="Observación"
                          class="my-2"
                          outline
                        />
                      </div>
                    </form>
                  </div>
                </Transition>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="mensaje-no-items"
    >
      <font-awesome-icon
        icon="calendar"
        size="5x"
        class="icono"
      />
      <p class="texto">
        No tienes clientes por atender
      </p>
    </div>
    <!-- fin planificador -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarAgregarAlPlanificador"
      @close="mostrarAgregarAlPlanificador = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>Agregar al planificador</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="pt-0">
        <ClientesFiltros
          :dia-seleccionado="fechaDesde"
          :motivos="motivos"
          @alertaMensaje="alertaMensaje = $event"
          @clientesAgregados="consultarVisitas"
          @actualizar="cargaInicial"
          @cerrar="mostrarAgregarAlPlanificador = $event"
        />
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      mensaje="Se eliminará la visita a la sucursal."
      @cerrar="modalConfirmacionEliminar = false"
      @confirmar="eliminarPlanificacion()"
    />
    <NuevaActividad
      :mostrarAsideActividad="mostrarAsideActividad"
      :idPlanificacionSeleccionada="idPlanificacionSeleccionada"
      :idSucursal="idSucursalSeleccionada ? idSucursalSeleccionada : ''"
      :empresa="empresa"
      :adicionalesSucursal="{
        nom_suc: sucursalSeleccionada && sucursalSeleccionada.nom_suc,
        sec_zon: sucursalSeleccionada && sucursalSeleccionada.sec_zon,
        est_zon: sucursalSeleccionada && sucursalSeleccionada.est_zon,
        ciu_zon: sucursalSeleccionada && sucursalSeleccionada.ciu_zon,
      }"
      :adicionalesDatosPago="{
        // Datos requeridos de la empresa
        id_emp: sucursalSeleccionada && sucursalSeleccionada.id_emp,
        nom_emp: sucursalSeleccionada && sucursalSeleccionada.nom_emp,
        rif_emp: empresa && empresa.rif_emp,
        // Datos requeridos de la sucursal
        id_emp_suc: sucursalSeleccionada && sucursalSeleccionada.id_emp,
        id_suc: sucursalSeleccionada && sucursalSeleccionada.id_suc,
        id_tcl_suc: sucursalSeleccionada && sucursalSeleccionada.id_tcl_suc,
        id_zon_suc: sucursalSeleccionada && sucursalSeleccionada.id_zon_suc,
        nom_suc: sucursalSeleccionada && sucursalSeleccionada.nom_suc,
      }"
      @cerrar="mostrarAsideActividad=false"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import {
  mdbBadge,
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbInput,
} from 'mdbvue';
import ClientesFiltros from '@/components/ClientesFiltros.vue';
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import NuevaActividad from '@/components/NuevaActividad.vue';
import {diaActual, asignarColorEstado} from '@/funciones/funciones.js';
import {apiPost} from '@/funciones/api.js';
export default {
  name: 'Planificador',
  components: {
    ClientesFiltros,
    mdbBadge,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbInput,
    AlertaMensaje,
    NuevaActividad,
    ConfirmacionEliminar,
  },
  data() {
    return {
      // Filtros de fecha
      fechaActual: '',
      fechaDesde: '',
      fechaHasta: '',
      asignarColorEstado,
      mostrarAsideActividad: false,
      botonDeshabilitado: false,
      mostrarAgregarAlPlanificador: false, // Modal Agregar al planificador
      zonasp: {},
      idSucursalSeleccionada: '',
      empresa: {},
      sucursalSeleccionada: {},
      modalConfirmacionEliminar: false,
      idPlanificacionSeleccionada: '',
      // Fecha
      diaActual,
      mostrarFecha: false,
      alertaMensaje: {},
      sucursalesConActividadesPendientes: [],
      motivos: [],
      cambioFec: '',
      cambioHor: '',
      observacion: '',
      fechaDia: diaActual(),
      plaVencidas: false,
    };
  },
  watch: {
    idSucursalSeleccionada() {
      this.obtenerActividadesAunSinEntregar();
    },
    mostrarAsideActividad() {
      if (!this.mostrarAsideActividad) {
        this.obtenerActividadesAunSinEntregar();
      }
    },
    fechaHasta() {
      this.cargaInicial();
    },
    plaVencidas() {
      this.cargaInicial();
    },
  },
  mounted() {
    this.fechaActual = diaActual();
    this.fechaDesde = diaActual();
    this.fechaEn7Dias(this.fechaActual);
    this.cargaInicial();
  },
  methods: {
    fechaEn7Dias(FechaSeleccionado) {
      let semana = new Date(new Date(FechaSeleccionado).getTime()+ 7 * 24 * 60 * 60 * 1000);
      semana = `${semana.getFullYear()}-${(semana.getMonth()+1).toString().padStart(2, '0')}-${semana.getDate().toString().padStart(2, '0')}`;
      this.fechaHasta = semana;
    },
    eliminarPlanificacion() {
      this.botonDeshabilitado = true;
      apiPost({s: 'planificadorEliminarMv'}, {pla: this.idPlanificacionSeleccionada})
          .then((res) => {
            if (res.data.r) {
              this.alertaMensaje = {
                contenido: 'Operación exitosa',
                tipo: 'correcto',
              };
              this.modalConfirmacionEliminar = false;
              this.idPlanificacionSeleccionada = '';
              this.botonDeshabilitado = false;
              this.cargaInicial();
            } else {
              this.botonDeshabilitado = false;
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un problema',
                tipo: 'error',
              };
            }
          });
    },
    obtenerActividadesAunSinEntregar() {
      const sucursal = window.localStorage.getItem(`datos-actividad${this.empresa.id_emp}-${this.idSucursalSeleccionada}`);
      if (sucursal) {
        // Si la actividad no está ya agregada se agrega
        if (!this.sucursalesConActividadesPendientes.includes(this.idSucursalSeleccionada)) {
          this.sucursalesConActividadesPendientes.push(this.idSucursalSeleccionada);
        }
        // Si la actividad ya se encuentra agregada entonces no se modifica
      } else {
        this.sucursalesConActividadesPendientes = this.sucursalesConActividadesPendientes.filter((s) => s != this.idSucursalSeleccionada);
      }
    },
    obtenerDatosGuardados() {
      const existeDatos = window.localStorage.getItem(`planificador-${this.fechaDesde}`);
      if (existeDatos) {
        this.zonasp = JSON.parse(existeDatos);
        this.alertaMensaje = {
          contenido: `Ha ocurrido un error obteniendo los datos del planificador del día: ${this.fechaDesde}. Se han obtenido los datos almacenados en memoria`,
          tipo: 'correcto',
        };
      } else {
        this.alertaMensaje = {
          contenido: `Ha ocurrido un error obteniendo los datos del planificador del día: ${this.fechaDesde}. Por favor verifica que tienes conexión a internet e intenta de nuevo.`,
          tipo: 'error',
        };
      }
    },
    cargaInicial() {
      this.consultarVisitas();
    },
    consultarVisitas() {
      apiPost({s: 'planificadorMv'}, 'fec='+this.fechaDesde + '&al=' + this.fechaHasta + '&vencidas=' + this.plaVencidas)
          .then((res) => {
            this.enlistarEmpresas(res.data.pla);
            this.totalSucursales = res.data.pla.length;
            this.motivos = res.data.mot;
          })
          .catch(() => {
            this.obtenerDatosGuardados();
          });
    },
    enlistarEmpresas(data) {
      const zonas = {};
      data.forEach((e) => {
        if (!zonas[e.fec_pla]) {
          zonas[e.fec_pla] = {
            fec_pla: e.fec_pla,
            zonas: {},
          };
        }
        if (!zonas[e.fec_pla].zonas[e.id_zon_suc]) {
          zonas[e.fec_pla].zonas[e.id_zon_suc] = {
            nom_zon: e.sec_zon + ' ' + e.ciu_zon + ' ' + e.est_zon,
            id_emp: e.id_emp,
            nom_emp: e.nom_emp,
            nom_suc: e.nom_suc,
            ciu_zon: e.ciu_zon,
            dir_suc: e.dir_suc,
            est_zon: e.est_zon,
            expandida: false,
            sucursales: [],
          };
        }
        e.expandida = false;
        e.editar = false;
        zonas[e.fec_pla].zonas[e.id_zon_suc].sucursales.push(e);
      });
      // Agregar consulta al caché exista o nó el item
      const existeDatos = window.localStorage.getItem(`planificador-${this.fechaDesde}`);
      if (existeDatos) {
        window.localStorage.removeItem(`planificador-${this.fechaDesde}`);
      }
      if (Object.keys(zonas).length) {
        window.localStorage.setItem(`planificador-${this.fechaDesde}`, JSON.stringify(zonas));
      }
      this.zonasp = zonas;
    },
    fechaFormateada(fec) {
      const fecha = new Date(new Date(fec).getFullYear(), new Date(fec).getMonth(+1), new Date(fec).getDate()+1);
      return fecha.toLocaleDateString('es-ES', {weekday: 'long', month: 'long', day: '2-digit'});
    },
    guardarCambio(fec, hor, obs, s, zp, z, editar) {
      if (!editar) {
        this.cambioFec = fec;
        this.cambioHor = hor;
        this.observacion = obs;
        this.zonasp[zp].zonas[z].sucursales[s].editar = true;
      } else if (!this.observacion.length && this.cambioHor && this.cambioFec) {
        this.alertaMensaje = {
          contenido: 'Agregue el motivo de la replanificación',
          tipo: 'alerta',
        };
      } else if (this.cambioFec < this.fechaActual) {
        this.alertaMensaje = {
          contenido: 'La fecha nueva debe ser mayor a la actual',
          tipo: 'alerta',
        };
      } else {
        this.botonDeshabilitado = true;
        const data = {
          fec: this.cambioFec != fec ? this.cambioFec : fec,
          hor: this.cambioHor != hor ? this.cambioHor : hor,
          obs: this.observacion,
          id: this.idPlanificacionSeleccionada,
        };
        apiPost({s: 'planificadorEditarMv'}, data)
            .then((res) => {
              if (res.data.r) {
                this.alertaMensaje = {
                  contenido: 'Operación exitosa',
                  tipo: 'correcto',
                };
                this.idPlanificacionSeleccionada = '';
                this.zonasp[zp].zonas[z].sucursales[s].editar = false;
                this.cargaInicial();
              } else {
                this.alertaMensaje = {
                  contenido: 'Ha ocurrido un problema',
                  tipo: 'error',
                };
              }
            })
            .catch(() => {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un problema',
                tipo: 'error',
              };
            });
        this.botonDeshabilitado = false;
        this.cambioFec = '';
        this.cambioHor = '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.acordeon {
  margin: 0 1rem;
  max-width: 730px;

  @media screen and (min-width: 992px) {
    margin: 0 auto;
  }
  @media screen and (min-width: 1200px) {
    max-width: 940px;
  }
}
</style>
